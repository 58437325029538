@import "../../utils/styles.scss";

.home {
  animation: fadeIn 0.5s;
  padding: 0 0 20px 0;
  .square {
    width: 200px;
    height: 200px;
    background: grey;
  }

  .shadow-contanier {
    position: relative;
    @include shadow-contanier;
    margin: 15px auto 0;
    padding: 0px 50px 40px;
    .home-header {
      display: flex;
      justify-content: center;
      .img-warp {
        width: 300px;
        height: 300px;
        overflow-y: hidden;
        border-radius: 50%;
        .main-img {
          width: 100%;
        }
      }
    }
    .title {
      @include title;
    }

    .sub-title {
      line-height: 1;
      margin: 0;
      margin-top: 20px;
    }
  }
}
