$MobileScreen: 400px;
$textColor: #83abce;

@keyframes fadeIn {
  0% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@font-face {
  font-family: "open-sans";
  src: url("../Assets/Open_Sans/static/OpenSans/OpenSans-Regular.ttf");
}

@font-face {
  font-family: "open-sans-bold";
  src: url("../Assets/Open_Sans/static/OpenSans/OpenSans-Bold.ttf");
}
@mixin shadow-contanier {
  height: fit-content;
  border-radius: 6px;
  padding: 0px 50px 97px;
  background-color: white;
  width: 100%;
  margin: 15px auto;
  line-height: 2.5;
  box-shadow: 0px 1px 18px -1px rgb(0 0 0 / 68%);
  max-width: 800px;
  min-height: 400px;
  box-sizing: border-box;
  @media (max-width: $MobileScreen) {
    max-width: unset;
    padding: 0px 5px 40px;
    box-shadow: unset;
  }
}

@mixin title {
  margin: 0;
  color: #83abce;
}
