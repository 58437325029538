.box {
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  padding: 32px;
  max-width: 736px;
  max-height: 500px;
  box-sizing: border-box;
  margin: auto;
  button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: navajowhite;
    background: transparent;
    font-size: 20px;
  }
}

@media screen and (max-width: 768px) {
  .box {
    max-width: 300px;
  }
}
