.app {
  margin: 0;
  padding: 0;
  font-size: 18px;
  line-height: 1.6;
  letter-spacing: 0.9px;
  font-family: open-sans;
  direction: rtl;
  padding-bottom: 80px;
  .center {
    margin-top: 0;
    text-align: center;
    overflow-y: auto;
    height: 100%;
  }
}
@font-face {
  font-family: "open-sans";
  src: URL("./Assets/Open_Sans/static/OpenSans/OpenSans-Regular.ttf");
}

@font-face {
  font-family: "open-sans-bold";
  src: URL("./Assets/Open_Sans/static/OpenSans/OpenSans-Bold.ttf");
}
