.article-card {
  a {
    text-decoration: none;
    color: inherit;
  }
  .article-text {
    max-height: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
