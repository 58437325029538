.footer {
  height: 49px;
  width: 100%;
  background-color: white;
  border-top: 1px solid rgb(225, 225, 225);
  position: fixed;
  bottom: 0;
  .footer-raw {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 9px;
  }
}
