@import "../../utils/styles.scss";

.about {
  animation: fadeIn 0.5s;
  display: flex;
  .text-warp {
    @include shadow-contanier;
    @media (max-width: $MobileScreen) {
      padding: 0px 5px 40px;
    }
    .title {
      @include title;
    }

    .text {
      margin: 0;
      .conflicts {
        margin: 0;
        padding: 0;
        font-family: open-sans-bold;
      }
    }
  }
}
